<template>
  <b-card no-body>
    <div>
      <div class="d-flex align-items-baseline mb-2">
        <b-badge
          variant="link"
          class="cursor-pointer back-btn"
          @click="$emit('cancel')"
        >
          <feather-icon icon="ArrowLeftIcon" />
          <span class="d-none d-md-inline">Back</span>
        </b-badge>
        |
        <b-badge
          class="ml-0"
          variant="link"
        >
          <span class="d-none d-md-inline">
            {{ formData ? `Edit Billing Information of ${formData.alias}` : 'Add New Billing Information' }}
          </span>

          <span class="d-md-none">
            {{ formData ? formData.alias : 'Add New Billing Information' }}
          </span>
        </b-badge>
      </div>

      <b-form-group
        v-if="!editingFor"
        class="col col-md-4 px-0 px-md-1"
        label="Payment Type"
      >
        <v-select
          id="countryList"
          v-model="paymentType"
          placeholder="Select Payment Type"
          style="width: 100%"
          label="name"
          :reduce="item => item.code"
          :options="paymentTypes"
        />
      </b-form-group>
    </div>
    <div
      v-if="paymentType || editingFor"
      class="px-0 px-md-1"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <swift-form
            v-if="paymentType=='swift' || editingFor=='BillingInformation'"
            :country-option="countryOption"
            :is-processing="isProcessing"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="$emit('cancel')"
          />
          <ach-form
            v-if="paymentType=='ach' || editingFor=='AchBillingInformation'"
            :country-option="countryOption"
            :is-processing="isProcessing"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="$emit('cancel')"
          />
          <fx-form
            v-if="paymentType=='fx' || editingFor=='FxBillingInformation'"
            :country-option="countryOption"
            :is-processing="isProcessing"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="$emit('cancel')"
          />
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import { ValidationObserver, extend } from 'vee-validate'
import {
  BFormGroup, BForm, BCard, BBadge,
} from 'bootstrap-vue'
import { required, email } from 'vee-validate/dist/rules'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import SwiftForm from './billing.vue/SwiftForm.vue'
import AchForm from './billing.vue/AchForm.vue'
import FxForm from './billing.vue/FxForm.vue'

export default {
  components: {
    ValidationObserver,
    BFormGroup,
    BForm,
    BCard,
    BBadge,
    vSelect,
    SwiftForm,
    AchForm,
    FxForm,
  },
  props: {
    formData: {
      type: Object,
      default: () => [],
    },
    countryOption: {
      type: Array,
      default: () => [],
    },
    editingFor: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      form: {},
      emailValue: '',
      name: '',
      required,
      email,
      clevePhone: {
        phone: true,
      },
      paymentTypes: [
        {
          code: 'swift',
          name: 'Swift',
        },
        {
          code: 'ach',
          name: 'ACH',
        },
        {
          code: 'fx',
          name: 'Fx',
        },
      ],
      isProcessing: false,
      paymentType: null,
    }
  },
  watch: {
    userInfoData(newValue) {
      if (newValue[0]) {
        const userInfo = { ...newValue[newValue.length - 1] }
        userInfo.country = userInfo.country?.code
        this.userInfo = userInfo
      }
    },
  },
  created() {
    extend('swiftCode', value => {
      if (value.length > 11) {
        return 'The Bank Swift Code must be less than 11 characters'
      } if (value.split(' ').length > 1) {
        return 'The Bank Swift Code cannot contain space'
      }
      return true
    })

    if (this.formData) {
      this.form = {
        ...this.formData,
        country: this.formData.country?.code,
      }
    }
  },
  methods: {
    validationForm(param) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const form = param

          if (form.__typename === 'AchBillingInformation') this.paymentType = 'ach'
          else if (form.__typename === 'FxBillingInformation') this.paymentType = 'fx'
          delete form.__typename
          delete form.country.__typename

          useApollo.users.postBillingSetting({
            userUid: getUserData().uuid,
            input: form,
          }, this.paymentType).then(() => {
            this.$store.dispatch('project/setInformationStatus', {
              hasBankingInformation: true,
            })
            this.showSuccess('Billing updated successfully!')
            this.$emit('refetch')
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.back-btn {
  transition: all 0.3s ease-in;
  &:hover {
    background-color: #82868b;
  }
}
</style>
