<template>
  <b-card>
    <billing-info-form
      v-if="showBillingInfoForm"
      :form-data="selectedInfo"
      :country-option="countryOption"
      :editing-for="selectedInfo ? selectedInfo.__typename : null"
      @refetch="() => { $emit('refetch'); selectedInfo = null; showBillingInfoForm = false }"
      @cancel="onCancel"
    />

    <b-row v-else>
      <b-col
        v-for="data in userInfoData"
        :key="data.id"
        md="4"
        class="mb-1"
      >
        <b-card
          border-variant="primary"
          class="h-100 mb-1"
        >
          <div class="d-flex flex-column justify-content-between h-100">
            <div>
              <b-row class="justify-content-between">
                <b-col
                  :cols="10"
                  :md="10"
                >
                  <h6>{{ data.alias }}</h6>
                  <p class="mb-0">
                    {{ data.bankAccount }}
                  </p>
                </b-col>
                <b-col
                  :cols="2"
                  :md="2"
                >
                  <b-badge
                    class="edit-btn cursor-pointer"
                    @click="() => { selectedInfo = {...data}; showBillingInfoForm = true }"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-badge>
                </b-col>
              </b-row>

              <p class="mb-2">
                {{ data.bankName }}
              </p>
            </div>
            <b-card-footer class="text-right pb-0">
              {{ getBillingType(data.__typename) }}
            </b-card-footer>
          </div>
        </b-card>
      </b-col>

      <!-- Add New Info -->
      <b-col
        md="4"
        class="mb-1"
        style="min-height: 200px;"
        @click="() => { showBillingInfoForm = true }"
      >
        <div
          class="new-wallet-wrap text-center cursor-pointer centralize h-100"
          @click="() => { showBillingInfoForm = true }"
        >
          <feather-icon
            icon="PlusIcon"
            size="42"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardFooter, BBadge, BRow, BCol,
} from 'bootstrap-vue'
import BillingInfoForm from './BillingInfoForm.vue'

export default {
  components: {
    BCard,
    BCardFooter,
    BBadge,
    BRow,
    BCol,
    BillingInfoForm,
  },
  props: {
    userInfoData: {
      type: Array,
      default: () => [],
    },
    countryOption: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showBillingInfoForm: false,
      selectedInfo: null,
    }
  },
  watch: {
    userInfoData: {
      handler(val) {
        if (val.length && this.$route.params.id) {
          this.selectedInfo = val.find(data => data.id === this.$route.params.id)
          if (this.selectedInfo) this.showBillingInfoForm = true
        }
      },
      immediate: true,
    },
  },
  methods: {
    getBillingType(type) {
      switch (type) {
        case 'AchBillingInformation':
          return 'ACH'
        case 'FxBillingInformation':
          return 'FX'
        default:
          return 'SWIFT'
      }
    },
    onCancel() {
      if (this.$route.params.fromPage === 'accounts') this.$router.push({ name: 'wallet' })
      this.showBillingInfoForm = false
      this.selectedInfo = null
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
</style>
